import styled from 'styled-components';
import Section from 'components/layout/Section';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import theme from 'utils/theme';
import { mobile, tablet, desktop } from 'utils/media';
import Container from 'components/layout/Container';

export const PressBannerContainer = styled(Container)`
  & {
    padding: 0 20px;
  }

  ${mobile} {
    & {
      padding: 0 20px 20px;
    }
  }

  ${tablet} {
    & {
      padding: 0;
    }
  }
`;

export const PressBannerSection = styled(Section)`
  width: 100%;
  max-width: ${theme.pageWidth.standard}px;
  padding: 0;

  ${tablet} {
    padding: 40px 0;
  }

  ${desktop} {
    margin: auto;
    padding: 60px 0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.offWhite,
  zIndex: 0,
});

export const PressBannerContent = styled.div`
  & {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1920px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  ${mobile} {
    & {
      padding: 20px 20px 0;

      & div {
        width: 100%;
        min-height: 0;
        max-width: 100px;
        height: auto;
        max-height: 30px;
      }

      & span {
        max-width: 200px;
        flex-basis: 43.333333%;

        &:nth-child(-n + 2) {
          padding-bottom: 20px;
        }

        &:last-child {
          display: none;
        }

        &:nth-last-child(2) {
          display: none;
        }
      }
    }
  }

  ${tablet} {
    & {
      flex-basis: initial;
      align-items: center;
      padding: 0 20px;

      & div {
        width: 100%;
        min-height: 30px;
        padding: 0 10px;
        max-width: 160px;
        height: auto;
        max-height: 38px;
      }

      & span {
        justify-content: center;

        &:nth-last-child(-n + 2) {
          display: none;
        }
      }
    }
  }

  ${desktop} {
    & {
      padding: initial;

      & span:nth-last-child(-n + 2) {
        display: flex;
      }
    }
  }
`;

export const PressBannerItem = styled.span`
  box-sizing: content-box;
  flex: 1;
  display: flex;

  ${tablet} {
    padding: 0;
    max-width: 250px;
  }

  ${desktop} {
    max-width: 320px;
    justify-content: space-around;
  }

  ${mobile} {
    justify-content: space-around;
  }
`;
