import React, { ReactElement, ReactNode } from 'react';
import Head from 'next/head';
import Header from 'components/global/Header';
import HeaderAlert from 'components/global/HeaderAlert';
import CookieBanner from 'components/global/CookieBanner';
import Footer from 'components/global/Footer';
import { ModalProvider } from 'styled-react-modal';
import EmailPopup from 'components/EmailPopup';
import PressBanner from 'components/global/PressBanner';
import {
  StickyGlobalHeader,
  SpecialModalBackground,
  HeaderAlertContainer,
} from './styles';
import { LayoutProps } from '../types';
import { GlobalSettings } from 'utils/types';

export default function DefaultLayout({ children, settings }: LayoutProps) {
  return (
    <>
      <Head>
        <title>Sproutl</title>
        <meta
          name="description"
          content="Growing for everyone. Beautiful plants for any garden, delivered to your door."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {settings.header?.alert ? (
        <HeaderAlertContainer>
          <HeaderAlert alert={settings.header.alert} />
        </HeaderAlertContainer>
      ) : null}

      <StickyGlobalHeader>
        <Header nav={settings.header?.menu || []} />
      </StickyGlobalHeader>

      <CookieBanner />
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        {children}
        <PressBanner images={settings.pressBanner?.images} />
        <Footer
          linkColumns={settings.footer?.linkColumns}
          newsletterColumn={settings.footer?.newsletterColumn}
        />
        <EmailPopup />
      </ModalProvider>
    </>
  );
}

export function getDefaultLayout(
  page: ReactElement,
  settings: GlobalSettings,
): ReactNode {
  return <DefaultLayout settings={settings}>{page}</DefaultLayout>;
}
