import React from 'react';
import {
  PressBannerSection,
  StyledResponsiveBox,
  FlexRow,
  PressBannerContent,
  PressBannerItem,
  PressBannerContainer,
} from './styles';
import Image from 'components/Image';

export interface AssetProps {
  _ref: string;
  _type: string;
}

export interface CtaProps {
  url: string;
}

export interface ImagesProps {
  _key: string;
  _type: string;
  alt?: string;
  cta?: CtaProps;
  asset?: AssetProps;
}

export interface PressBannerProps {
  images?: ImagesProps[];
  display?: any;
}

function PressBanner({ images }: PressBannerProps) {
  return (
    <PressBannerSection>
      <PressBannerContainer noPadding>
        <FlexRow>
          <PressBannerContent>
            {images
              ? images.map((press) => (
                  <PressBannerItem key={press._key}>
                    <StyledResponsiveBox aspectRatio={3 / 4}>
                      <a
                        href={press.cta?.url ? press.cta?.url : ''}
                        target={press.cta?.url ? 'blank' : ''}
                      >
                        <Image
                          src={press.asset}
                          aspectRatio={1 / 7}
                          sizes={`200px`}
                          alt={press.alt || ''}
                          objectFitContain
                        />
                      </a>
                    </StyledResponsiveBox>
                  </PressBannerItem>
                ))
              : null}
          </PressBannerContent>
        </FlexRow>
      </PressBannerContainer>
    </PressBannerSection>
  );
}

export default PressBanner;
